<template>
  <div>
    <v-dialog v-model="addSourceLocationDialog" persistent width="500px">
      <v-toolbar dense class="gradient-bg elevation-0">
        <v-toolbar-title class="white--text"
          >{{
            SourceObject.item == null
              ? "Add Source for Location - " + send_LocationName
              : "Edit Source - " + SourceObject.qr_location_name
          }}
        </v-toolbar-title>
        <v-spacer />
        <v-icon @click="close_dialog()" class="white--text mr-n3"
          >mdi-close</v-icon
        >
      </v-toolbar>
      <v-card class="overflow-hidden">
        <v-form ref="form">
          <v-row class="mx-3" no-gutters>
            <v-col cols="12" md="12" lg="12" xl="12">
              <v-text-field
                class="mt-3"
                :label="
                  // thi one is error
                  SourceObject.item == null
                    ? 'Enter Source Name *'
                    : 'Edit Source Name'
                "
                v-model="source_name"
                dense
                outlined
                :rules="src_name_rules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" lg="12" xl="12">
              <v-select
                class=""
                :label="
                  // thi one is error
                  SourceObject.item == null
                    ? 'Select Source Type *'
                    : 'Select Source Type'
                "
                v-model="source_type"
                :items="sourcetypeitems"
                item-text="text"
                item-value="value"
                dense
                outlined
                :rules="src_name_rules"
              ></v-select>
            </v-col>
            <v-col cols="12" md="12" lg="12" xl="12">
              <v-select
                class="mt-n2"
                v-model="direction"
                label="Select Direction for Source *"
                dense
                outlined
                :items="directionItems"
                item-text="text"
                item-value="value"
                :rules="src_name_rules"
              ></v-select>
              <!-- <v-row v-if="SourceObject != null" class="mt-n5" no-gutters>
            <v-card-text align="center">
              <v-btn
                @click="validate_edit_src_dialog()"
                :loading="src_loading"
                small
                class="gradient-bg white--text"
                ><v-icon class="mr-2">mdi-pencil</v-icon>Save</v-btn
              >
            </v-card-text>
          </v-row> -->
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions class="justify-center">
          <v-btn
            @click="validate_src_dialog()"
            :loading="src_loading"
            class="gradient-bg white--text mb-5"
            small
            ><v-icon class="mr-2">mdi-plus</v-icon>Add Source</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <DeleteDialog
      :confDeleteDialog="confDeleteDialog"
      @close_conf_delete_dialog="confDeleteDialog = false"
      :deleteText="deleteText"
      :deleteIcon="deleteIcon"
      :deleteColor="deleteColor"
      @success_conf_delete="deleteSuccessAction"
      :deleteLoading="deleteLoading"
    />
  </div>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { AddQrToLocations, EditSourceLocation } from "@/graphql/mutations.js";
import SnackBar from "@/components/SnackBar.vue";
import DeleteDialog from "@/components/Dialogs/ConfirmationDialogs/DeleteDialog.vue";

export default {
  components: {
    SnackBar,
    DeleteDialog,
  },
  props: {
    addSourceLocationDialog: Boolean,
    send_LocationId: String,
    send_LocationName: String,
    SourceObject: Object,
  },
  data: () => ({
    deleteLoading: false,
    confDeleteDialog: false,
    deleteColor: "",
    source_type: "QR",
    deleteIcon: "",
    deleteText: "",
    SnackBarComponent: {},
    sourcetypeitems: [
      { text: "QR", value: "QR" },
      { text: "RFID", value: "RFID" },
    ],
    src_name_rules: [
      (v) => !!v || "Required",

      (v) => v.length <= 30 || "Source Name must be under 30 characters",
    ],
    source_name: "",
    direction: "IN",
    SourcetypeItems: [
      // { text:"QR SCAN", value:"QR SCAN" },
      // { text:"BEACON", value: "BEACON" },
    ],
    directionItems: [
      { text: "IN", value: "IN" },
      { text: "OUT", value: "OUT" },
      { text: "NEUTRAL", value: "NEUTRAL" },
    ],
    src_loading: false,
  }),
  watch: {
    addSourceLocationDialog: {
      handler() {
        {
          if (this.addSourceLocationDialog == true) {
            this.direction="IN",
            this.source_name="",
            this.source_type="QR"
            // console.log(  this.SourceObject);
            // this.source_name =
            //   this.SourceObject == null
            //     ? ""
            //     : this.SourceObject.qr_location_name;
            // this.direction =
            //   this.SourceObject == null
            //     ? "IN"
            //     : this.SourceObject.location_direction;
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    deleteSuccessAction() {
      this.deleteLoading = true;
      this.add_src_for_location();
    },
    confirmation_add_src() {
      this.confDeleteDialog = true;
      this.deleteText =
        "Adding this Source will be charged 250 Credits. Confirm ?";
      this.deleteIcon = "mdi-qrcode";
      this.deleteColor = "green";
    },
    validate_edit_src_dialog() {
      if (this.$refs.form.validate()) {
        this.edit_src_for_location();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Invalid Inputs",
        };
      }
    },
    validate_src_dialog() {
      if (this.$refs.form.validate()) {
        this.add_src_for_location();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Invalid Inputs",
        };
      }
    },
    async edit_src_for_location() {
      this.src_loading = true;
      try {
        var inputParams = {
          qr_location_name: this.source_name,
          location_id: this.SourceObject.location_id,
          location_direction: this.direction,
          location_type: this.SourceObject.location_type,
        };
        for (const [key, value] of Object.entries(inputParams)) {
          if (value == "") delete inputParams[key];
        }
        await API.graphql(
          graphqlOperation(EditSourceLocation, {
            input: inputParams,
          })
        ).then((res) => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: res.data.EditSourceLocation.Message,
          };
          this.src_loading = false;
          this.$emit("close_src_loc_get_src");
        });
      } catch (err) {
        console.log(err);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
        this.src_loading = false;
      }
    },
    async add_src_for_location() {
      this.src_loading = true;
      try {
        var inputParams = {
          organisation_id:
            this.$store.getters.get_org_details[0].organisation_id,
          qr_location_name: this.source_name,
          user_email_id: this.$store.getters.get_useremail,
          location_id: this.send_LocationId,
          qr_direction: this.direction,
          action_creation_type: this.source_type,
        };
        for (const [key, value] of Object.entries(inputParams)) {
          if (value == "") delete inputParams[key];
        }
        await API.graphql(
          graphqlOperation(AddQrToLocations, {
            input: inputParams,
          })
        ).then((res) => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: res.data.AddQrToLocations.Message,
          };
          this.src_loading = false;
          this.deleteLoading = false;
          this.confDeleteDialog = false;
          this.$emit("close_src_loc_get_location");
        });
      } catch (err) {
        console.log(err);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
        this.src_loading = false;
      }
    },
    close_dialog() {
      // this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$emit("close_src_location_dialog");
    },
  },
};
</script>

<style></style>
