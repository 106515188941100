<template>
  <div>
    <v-dialog v-model="viewMemberQRDialog" persistent width="350px">
      <v-toolbar dense class="elevation-0 gradient-bg white--text">
        <v-toolbar-title
          >QR for
          {{
            locationObjectMain.location_name != undefined
              ? locationObjectMain.location_name
              : locationObjectMain.location_name
          }}</v-toolbar-title
        >
        <v-spacer />
        <v-icon @click="close_dialog()" class="white--text mr-n3"
          >mdi-close</v-icon
        >
      </v-toolbar>
      <v-card class="overflow--hidden">
        <v-card-text align="center" justify="center">
          <v-img contain width="140px" v-if="url" :src="url" height="90px">
          </v-img>
          <v-img
            v-else
            src="@/assets/prezsqu.png"
            class=""
            height="145"
            width="145"
          >
          </v-img>
        </v-card-text>
        <div v-if="viewSourceItems != undefined && viewSourceItems != ''">
          <v-card-text class="mt-n5" align="center" justify="center">
            <VueQRCodeComponent
              :text="member_qr"
              error-level="H"
              :size="150"
            ></VueQRCodeComponent>
          </v-card-text>
          <v-card-text align="center" justify="center">
            <!-- <v-img src="@/assets/prezwww.png" class="mt-n3" max-width="180">
                    </v-img
                  > -->
            <span class="black--text subtitle-2">
              {{ $store.getters.get_org_details[0].organisation_name }}<br />
              {{ locationObjectMain.location_name }} </span
            ><br />
            <span class="primary--text subtitle-2">www.prznce.com/App</span>
          </v-card-text>

          <v-card-text align="center" justify="center">
            <v-btn
              class="gradient-bg white--text mt-n5 ml-3"
              @click="generateReport()"
              ><v-icon medium class="mr-2">mdi-qrcode-scan</v-icon>Print
              QR</v-btn
            >
          </v-card-text>
        </div>
        <div v-else>
          <v-card-text
            class="mt-n5"
            align="center"
            justify="center"
            v-if="loadvalue == true"
          >
            Please regenerate the QR code, it was not generated properly.

            <v-card-text align="center" justify="center">
              <v-btn
                class="gradient-bg white--text mt-5"
                @click="list_sources_locations()"
              >
                Regenerate</v-btn
              >
            </v-card-text>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>
    <div v-if="viewMemberQRDialog == true">
      <v-card>
        <v-card-text align="center" justify="center" style="margin-left: -10px">
          <vue-html2pdf
            :float-layout="false"
            :enable-download="true"
            :preview-modal="false"
            :filename="
              locationObjectMain.location_name != undefined
                ? locationObjectMain.location_name
                : locationObjectMain.location_name + ' - QR'
            "
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a6"
            pdf-orientation="portrait"
            :paginate-elements-by-height="800"
            pdf-content-width="420px"
            ref="html2Pdf"
          >
            <section slot="pdf-content">
              <v-card-text
                align="center"
                justify="center"
                style="margin-left: -5px"
              >
                <img
                  contain
                  width="200px"
                  v-if="url"
                  :src="url"
                  height="100px"
                />
                <img
                  v-else
                  src="@/assets/prezsqu.png"
                  class="mt-n5"
                  height="120"
                  width="100"
                />
              </v-card-text>
              <v-card-text class="mt-n5" align="center" justify="center">
                <VueQRCodeComponent
                  :text="member_qr"
                  error-level="H"
                  :size="200"
                ></VueQRCodeComponent>
              </v-card-text>
              <v-card-text align="center" justify="center" class="mt-5">
                <!-- <v-img src="@/assets/prezwww.png" class="mt-n3" max-width="180">
                    </v-img
                  > -->
                <span class="black--text subtitle-2 font-weight-bold">
                  <b>
                    {{ $store.getters.get_org_details[0].organisation_name }}
                    <br />

                    {{ locationObjectMain.location_name }}</b
                  >
                </span>
                <br />
                <span class="primary--text subtitle-2 font-weight-bold">
                  <b> www.prznce.com/App </b></span
                >
              </v-card-text>
            </section>
          </vue-html2pdf>
        </v-card-text>
      </v-card>
    </div>
    <Overlay :overlay="overlay" />
  </div>
</template>
    
    <script>
import Overlay from "@/components/Overlay.vue";
import VueQRCodeComponent from "vue-qr-generator";
import { API, graphqlOperation } from "aws-amplify";
import { GetSourcesTaggedToLocations } from "@/graphql/queries.js";
import VueHtml2pdf from "vue-html2pdf";
export default {
  props: {
    viewMemberQRDialog: Boolean,
    locationObjectMain: Object,
  },
  components: { VueQRCodeComponent, VueHtml2pdf, Overlay },
  data: () => ({
    member_qr: "",
    url: "",
    viewSourceItems: "",
    loadvalue: false,
    overlay: false,
  }),
  created() {
    this.url = this.$store.getters.get_org_details[0].organisation_logo;
  },
  // watch: {
  //   viewMemberQRDialog: {
  //     handler() {
  //       {
  //         this.list_sources_locations();
  //         setTimeout(() => {
  //           if (this.viewMemberQRDialog == true) {
  //             // setTimeout(() => {

  //             setTimeout(() => {
  //               this.member_qr = `https://www.prznce.com/Apps/myprezence?qr_id=${this.viewSourceItems}`;
  //             }, 300);
  //             // console.log("asd", this.viewSourceItems);
  //             // this.member_qr =
  //             //   this.locationObjectMain.user_id != undefined
  //             //     ? `https://www.prezence.app/Apps/myprezence?qr_id=PROXY#${this.locationObjectMain.user_id}`
  //             //     : `https://www.prezence.app/Apps/myprezence?qr_id=${this.viewSourceItems}`;
  //             // console.log(this.viewSourceItems, "member_qr");
  //             // }, 100);
  //           }
  //         }, 300);
  //       }
  //     },
  //     immediate: true,
  //   },
  // },
  watch: {
    viewMemberQRDialog: {
      async handler() {
        if (this.viewMemberQRDialog == true) {
          this.overlay = true;
          await this.list_sources_locations();
        }
      },
      immediate: true,
    },
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    close_dialog() {
      this.$emit("close_view_qr_dialog");
    },
    async list_sources_locations() {
      this.overlay = true;
      this.isLoading = true;
      try {
        await API.graphql(
          graphqlOperation(GetSourcesTaggedToLocations, {
            input: {
              location_id: this.locationObjectMain.location_id,
            },
          })
        ).then((res) => {
          // console.log(
          //   res.data.GetSourcesTaggedToLocations.data[0],
          //   "simplified"
          // );
          // console.log(res.data.GetSourcesTaggedToLocations, "example");
          this.loadvalue = true;
          this.viewSourceItems =
            res.data.GetSourcesTaggedToLocations.data[0].qr_id;
          this.member_qr = `https://www.prznce.com/Apps/myprezence?qr_id=${this.viewSourceItems}`;
          // res.data.GetSourcesTaggedToLocations.data[0] != 0
          //   ? res.data.GetSourcesTaggedToLocations.data
          //   : [];
          // console.log(this.viewSourceItems, "id");
          this.overlay = false;
          this.isLoading = false;
          if (this.viewSourceItems == undefined || this.viewSourceItems == "") {
            this.overlay = false;
            this.$store.commit("snackbar/showMessage", {
              content: "Faild to Regenerate",
              color: "red",
            });
          }
        });
      } catch (err) {
        console.log("errrrr", err);
        this.$store.commit("snackbar/showMessage", {
          content: err.errors[0].message,
          color: "red",
        });
        this.overlay = false;
        this.isLoading = false;
      }
    },
  },
};
</script>
    
    <style></style>
    