<template>
  <div>
    <!--  eslint-disable -->
    <v-dialog v-model="confDeleteDialog" persistent width="460px">
      <v-card class="overflow-hidden">
        <v-toolbar dense class="gradient-bg elevation-0"> </v-toolbar>
        <v-card-text
          align="center"
          style="font-weight: bold; text-align: justify"
          >{{ deleteText }}
        </v-card-text>
        <v-card-text class="mt-n5" align="center">
          <v-icon large :color="deleteColor">{{ deleteIcon }}</v-icon>
        </v-card-text>
        <v-card-actions class="mt-n8">
          <v-spacer></v-spacer>
          <v-btn color="primary" small text @click="closeDelete">Cancel</v-btn>
          <v-btn
            :loading="deleteLoading"
            color="red darken-1"
            class="white--text"
            small
            @click="deleteItemConfirm"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: {
    confDeleteDialog: Boolean,
    deleteText: String,
    deleteIcon: String,
    deleteColor: String,
    deleteLoading: Boolean,
  },
  data: () => ({
    deleteMessage: "",
  }),
  methods: {
    closeDelete() {
      this.$emit("close_conf_delete_dialog");
    },
    deleteItemConfirm() {
      this.$emit("success_conf_delete");
    },
  },
};
</script>

<style>
.primary {
  color: #d97d54 !important;
}
</style>
