<template>
  <div>
    <v-dialog v-model="viewMemberQRDialog" persistent width="350px">
      <v-toolbar dense class="elevation-0 gradient-bg white--text">
        <v-toolbar-title
          >QR for
          {{
            userDetailsObj.user_name != undefined
              ? userDetailsObj.user_name
              : userDetailsObj.qr_location_name
          }}</v-toolbar-title
        >
        <v-spacer />
        <v-icon @click="close_dialog()" class="white--text mr-n3"
          >mdi-close</v-icon
        >
      </v-toolbar>
      <v-card class="overflow--hidden">
        <v-card-text align="center" justify="center">
          <v-img contain width="140px" v-if="url" :src="url" height="90px">
          </v-img>
          <v-img
            v-else
            src="@/assets/prezsqu.png"
            class=""
            height="145"
            width="145"
          >
          </v-img>
        </v-card-text>
        <v-card-text class="mt-n5" align="center" justify="center">
          <VueQRCodeComponent
            :text="member_qr"
            error-level="H"
            :size="150"
          ></VueQRCodeComponent>
        </v-card-text>
        <v-card-text align="center" justify="center">
          <!-- <v-img src="@/assets/prezwww.png" class="mt-n3" max-width="180">
                  </v-img
                > -->
          <span class="black--text subtitle-2">
            {{ $store.getters.get_org_details[0].organisation_name }}<br />
            {{ userDetailsObj.qr_location_name }} </span
          ><br />
          <span class="primary--text subtitle-2">www.prznce.com/App</span>
        </v-card-text>

        <v-card-text align="center" justify="center">
          <v-btn
            class="gradient-bg white--text mt-n3"
            @click="generateReport()"
            v-if="userDetailsObj.location_source_type != 'RFID'"
            ><v-icon medium class="mr-2">mdi-qrcode-scan</v-icon>Print QR</v-btn
          >
          <v-btn
            v-else
            class="gradient-bg white--text mt-n5 ml-3"
            @click="doCopy()"
            v-clipboard:copy="`${userDetailsObj.qr_id}`"
          >
            <v-icon small class="mr-2" color="white">mdi-content-copy</v-icon>
            Copy
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div v-if="viewMemberQRDialog == true">
      <v-card>
        <v-card-text align="center" justify="center" style="margin-left: -10px">
          <vue-html2pdf
            :float-layout="false"
            :enable-download="true"
            :preview-modal="false"
            :filename="
              userDetailsObj.user_name != undefined
                ? userDetailsObj.user_name
                : userDetailsObj.qr_location_name + ' - QR'
            "
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a6"
            pdf-orientation="portrait"
            :paginate-elements-by-height="800"
            pdf-content-width="420px"
            ref="html2Pdf"
          >
            <section slot="pdf-content">
              <v-card-text
                align="center"
                justify="center"
                style="margin-left: -5px"
              >
                <img
                  contain
                  width="200px"
                  v-if="url"
                  :src="url"
                  height="100px"
                />
                <img
                  v-else
                  src="@/assets/prezsqu.png"
                  class="mt-n5"
                  height="120"
                  width="100"
                />
              </v-card-text>
              <v-card-text class="mt-n5" align="center" justify="center">
                <VueQRCodeComponent
                  :text="member_qr"
                  error-level="H"
                  :size="200"
                ></VueQRCodeComponent>
              </v-card-text>
              <v-card-text align="center" justify="center">
                <!-- <v-img src="@/assets/prezwww.png" class="mt-n3" max-width="180">
                  </v-img
                > -->
                <span class="black--text subtitle-2 font-weight-medium">
                  <b>
                    {{ $store.getters.get_org_details[0].organisation_name
                    }}<br />
                    {{ userDetailsObj.qr_location_name }}
                  </b></span
                ><br />
                <span class="primary--text subtitle-2 font-weight-medium">
                  <b>www.prznce.com/App </b></span
                >
              </v-card-text>
            </section>
          </vue-html2pdf>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
  
  <script>
import VueQRCodeComponent from "vue-qr-generator";
import VueHtml2pdf from "vue-html2pdf";
export default {
  props: {
    viewMemberQRDialog: Boolean,
    userDetailsObj: Object,
  },
  components: { VueQRCodeComponent, VueHtml2pdf },
  data: () => ({
    member_qr: "",
    url: "",
  }),
  created() {
    this.url = this.$store.getters.get_org_details[0].organisation_logo;
  },
  watch: {
    viewMemberQRDialog(val) {
      if (val == true) {
        // console.log("asd3", this.userDetailsObj);
        this.member_qr =
          this.userDetailsObj.user_id != undefined
            ? `https://www.prznce.com/Apps/myprezence?qr_id=PROXY#${this.userDetailsObj.user_id}`
            : `https://www.prznce.com/Apps/myprezence?qr_id=${this.userDetailsObj.qr_id}`;
        // console.log(this.userDetailsObj, "member_qr");
      }
    },
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    close_dialog() {
      this.$emit("close_view_qr_dialog");
    },
    async doCopy() {
      this.$store.commit("snackbar/showMessage", {
        content: "QR URL Copied Successfully!",
        color: "green",
      });
    },
  },
};
</script>
  
  <style></style>
  