<template>
  <div>
    <v-dialog
      v-model="addLocationPromptDialog"
      persistent
      width="500px"
      height="800px"
    >
      <v-toolbar dense class="gradient-bg elevation-0">
        <v-toolbar-title class="white--text subtitle-1">
          Please choose the type of location</v-toolbar-title
        >
        <v-spacer />
      </v-toolbar>
      <v-card class="overflow-hidden">
        <v-card-text>
          <v-radio-group v-model="radioGroup" class="ml-4 mt-1" column>
            <v-row class="mt-3">
              <v-radio label="Member locations" value="Physical">
                <template v-slot:label>
                  <span class="black--text">Member location </span
                  ><span class="#42424--text"
                    >(for organization's members to scan)</span
                  >
                </template></v-radio
              >
            </v-row>
            <v-row class="mt-8">
              <v-radio label="Visitor locations" value="Visitor">
                <template v-slot:label>
                  <span class="black--text">Visitor location </span
                  ><span class="#42424--text">
                    (for organization's visitors to scan)</span
                  ></template
                ></v-radio
              >
            </v-row>
          </v-radio-group>
        </v-card-text>

        <v-card-actions class="mt-n6">
          <v-spacer></v-spacer>
          <v-btn @click="close()" class="gradient-bg white--text" small
            >cancel
          </v-btn>
          <v-btn
            @click="LocationType()"
            class="gradient-bg white--text"
            small
            v-if="radioGroup == 'Physical' || radioGroup == 'Visitor'"
            >ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    addLocationPromptDialog: Boolean,
    locationObjectMain: Object,
  },
  data: () => ({
    radioGroup: "",
  }),
  watch: {
    radioGroup(val) {
      if (val == "Physical") {
        this.Visitor = "";
      } else {
        this.Physical = "";
      }
    },
  },
  methods: {
    LocationType() {
      if (this.radioGroup == "Physical") {
        this.$emit("clickedPhysical");
      } else if (this.radioGroup == "Visitor") {
        this.$emit("clickedVisitor");
      }
      this.radioGroup = "";
    },
    close() {
      this.radioGroup = "";
      this.$emit("clicked");
    },
  },
};
</script>

<style></style>
