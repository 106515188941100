<template>
  <div>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <Overlay :overlay="overlay" />
    <v-dialog v-model="viewlocationtable" persistent width="390px">
      <v-card class="overflow-hidden">
        <v-toolbar dense class="gradient-bg elevation-0"> </v-toolbar>
        <v-card-text
          align="center"
          style="font-weight: bold; text-align: justify" class="mt-3"
          >Are you sure you want to send Location attached QR?
        </v-card-text>
        <div class="d-flex justify-center mb-3">
          <v-card-actions class="mt-2">
            <v-btn
              color="primary"
              class="white--text"
              @click="close_dialog()"
              small
              >cancel</v-btn
            >
            <v-btn
              color="primary"
              class="white--text"
              @click="location_qr_print()"
              small
              >Send</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
import { API, graphqlOperation } from "aws-amplify";
import { location_qr_print } from "@/graphql/queries.js";
import SnackBar from "@/components/SnackBar.vue";
import Overlay from "@/components/Overlay.vue";

export default {
  components: {
    SnackBar,
    Overlay,
  },
  props: {
    viewlocationtable: Boolean,
    locationObjectMain: Object,
  },
  data: () => ({
    SnackBarComponent: {},
    overlay: false,
  }),
  methods: {
    close_dialog() {
      this.$emit("close_viewlocationsstable_dialog");
    },
    async location_qr_print() {
      try {
        let result = await API.graphql(
          graphqlOperation(location_qr_print, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              location_id: this.locationObjectMain.location_id,
              user_email_id: this.$store.getters.get_useremail,
            },
          })
        );
        // console.log(result);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: " Location attached QR sent successful",
        };
        this.$emit("get_locationqr");
        this.viewlocationtable = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>