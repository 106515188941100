<template>
  <div>
    <v-dialog v-model="viewVisitorQRDialog" persistent width="500px">
      <v-toolbar dense class="elevation-0 gradient-bg white--text">
        <v-toolbar-title
          >QR for {{ visitorObject.location_name }}</v-toolbar-title
        >
        <v-spacer />
        <v-icon @click="close_dialog()" class="white--text mr-n3"
          >mdi-close</v-icon
        >
      </v-toolbar>
      <v-card class="overflow--hidden">
        <v-card-text align="center" justify="center" style="margin-left: -10px">
          <vue-html2pdf
            :float-layout="false"
            :enable-download="true"
            :preview-modal="false"
            :filename="visitorObject.location_name + ' - QR'"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a6"
            pdf-orientation="portrait"
            :paginate-elements-by-height="800"
            pdf-content-width="420px"
            ref="html2Pdf"
          >
            <section slot="pdf-content">
              <v-card-text align="center" justify="center">
                <v-img
                  src="@/assets/prezsqu.png"
                  class="mt-n5"
                  height="145"
                  width="145"
                >
                </v-img>
              </v-card-text>
              <v-card-text class="mt-n5" align="center" justify="center">
                <VueQRCodeComponent
                  :text="visitor_qr"
                  error-level="H"
                  :size="150"
                ></VueQRCodeComponent>
              </v-card-text>
              <v-card-text align="center" justify="center">
                <!-- <v-img src="@/assets/prezwww.png" class="mt-n3" max-width="180">
                </v-img -->

                <span class="black--text subtitle-2">
                  {{ $store.getters.get_org_details[0].organisation_name
                  }}<br />
                  {{ visitorObject.location_name }} </span
                ><br />
                <span class="primary--text subtitle-2">www.prznce.com/App</span>
              </v-card-text>
            </section>
          </vue-html2pdf>
        </v-card-text>
        <v-card-text align="center" justify="center">
          <v-btn
            class="gradient-bg white--text mt-n5 ml-3"
            @click="generateReport()"
            ><v-icon medium class="mr-2">mdi-qrcode-scan</v-icon>Print QR</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueQRCodeComponent from "vue-qr-generator";
import VueHtml2pdf from "vue-html2pdf";
export default {
  props: {
    viewVisitorQRDialog: Boolean,
    visitorObject: Object,
  },
  components: { VueQRCodeComponent, VueHtml2pdf },
  data: () => ({
    visitor_qr: "",
  }),
  watch: {
    viewVisitorQRDialog(val) {
      if (val == true) {
        // console.log("asd", this.visitorObject);
        this.visitor_qr = this.visitorObject.location_qr_link;
      }
    },
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    close_dialog() {
      this.$emit("close_view_qr_dialog");
    },
  },
};
</script>

<style></style>
