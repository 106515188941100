<template>
  <div>
    <!--  eslint-disable -->
    <v-dialog
      v-model="addLocationDialog"
      persistent
      max-width="
       1200px
      "
    >
      <v-toolbar dense class="gradient-bg elevation-0">
        <v-toolbar-title
          class="white--text"
          v-text="
            locationObjectMain.actionObject == 'ADD'
              ? 'Add Physical Location'
              : 'Edit Physical Location'
          "
        ></v-toolbar-title>
        <v-spacer />

        <v-icon @click="close_dialog()" class="white--text mr-n3"
          >mdi-close</v-icon
        >
      </v-toolbar>

      <v-card class="overflow-hidden">
        <v-card flat>
          <v-form ref="form">
            <v-row no-gutters class="mt-5 mb-5">
              <v-col cols="12" md="12" sm="12" lg="6" xl="6">
                <v-card
                  :loading="cardLoading"
                  class="ml-5 mr-2 elevation-1 mb-2"
                  flat
                >
                  <GmapMap
                    :center="
                      markers[0] != undefined
                        ? markers[0].position
                        : defaultCenter
                    "
                    :zoom="20"
                    map-type-id="terrain"
                    style="width: ; height: 300px"
                  >
                    <GmapMarker
                      :key="index"
                      v-for="(m, index) in markers"
                      :position="m.position"
                      :clickable="true"
                      :draggable="true"
                      @dragend="callposition"
                      @click="center = m.position"
                    />
                  </GmapMap>
                </v-card>
              </v-col>
              <v-col cols="12" md="12" sm="6" lg="6" xl="6">
                <v-text-field
                  v-model="location_name"
                  label="Enter Location Name *"
                  dense
                  :rules="loc_name_rules"
                  outlined
                  class="mr-4 mt-3 ml-4"
                ></v-text-field>
                <v-card class="ml-5" flat>
                  <gmap-autocomplete
                    placeholder="Search Location"
                    :value="search"
                    ref="clearSearch"
                    style="
                      border-bottom: 1px solid grey;
                      font-size: 18px;
                      width: 85%;
                    "
                    @place_changed="setPlace"
                  ></gmap-autocomplete>
                  <v-btn
                    rounded
                    small
                    class="white--text ml-6"
                    color="gradient-bg"
                    @click="addMarker"
                    >Go</v-btn
                  >
                </v-card>
                <v-row class="ml-4 mr-6 mt-3" no-gutters>
                  <v-col cols="12" md="12" xl="12" lg="12" sm="12">
                    <v-textarea
                      v-model="complete_address"
                      label="Selected Address"
                      disabled
                      dense
                      rows="1"
                      auto-grow
                      class="ml-2"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" md="6" sm="6" lg="6" xl="6">
                    <v-text-field
                      label="lattitude"
                      outlined
                      required
                      class="mr-2"
                      dense
                      :rules="latrules"
                      v-model="latt"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" sm="6" lg="6" xl="6">
                    <v-text-field
                      label="longitude"
                      outlined
                      required
                      dense
                      :rules="longrules"
                      v-model="longg"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" sm="12" lg="12" xl="12">
                    <div
                      v-if="locationObjectMain.actionObject == 'ADD'"
                      align="center"
                    >
                      <v-btn
                        @click="validate_add_location()"
                        :loading="locationLoading"
                        class="gradient-bg white--text"
                        >Create
                      </v-btn>
                    </div>
                    <div
                      align="center"
                      v-if="locationObjectMain.actionObject == 'EDIT'"
                    >
                      <v-btn
                        @click="validate_edit_location()"
                        :loading="locationLoading"
                        class="gradient-bg white--text"
                        ><v-icon class="mr-2">mdi-pencil</v-icon>Save
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-card>
    </v-dialog>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>

<script>
/* eslint-disable */
import SnackBar from "@/components/SnackBar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { AddQrToLocations } from "@/graphql/mutations.js";
import { CreateLocations, EditLocations } from "@/graphql/mutations.js";
export default {
  components: {
    SnackBar,
  },
  props: {
    addLocationDialog: Boolean,
    locationObjectMain: Object,
  },
  data: () => ({
    switch1: true,
    location_name: "",
    search: "",
    defaultCenter: {
      lat: 0,
      lng: 0,
    },
    markers: [],
    locationLoading: false,
    cardLoading: false,
    latrules: [(v) => !!v || "Lattitude is required"],
    longrules: [(v) => !!v || "Longitude is required"],
    complete_address: "",
    latt: "",
    longg: "",
    radioGroup: "",
    locationId: "",
    SnackBarComponent: {},
    count: 0,
    loc_name_rules: [
      (v) =>
        (typeof v === "string" && v.trim().length !== 0) ||
        "Location Name is required",

      (v) => v.length <= 40 || "Location Name must be under 40 characters",
    ],
  }),
  watch: {
    addLocationDialog: {
      handler() {
        {
          if (this.addLocationDialog == true) {
            if (this.locationObjectMain.actionObject == "ADD") {
              this.locationId = this.locationObjectMain.location_id;
              this.complete_address = "Current Location";
              this.GetCurrentLocation();
            } else if (this.locationObjectMain.actionObject == "EDIT") {
              this.location_name = this.locationObjectMain.location_name;
              if (
                this.locationObjectMain.geo_lat != null &&
                this.locationObjectMain.geo_long != null
              ) {
                this.latt = this.locationObjectMain.geo_lat;
                this.longg = this.locationObjectMain.geo_long;
                this.markers = [];
                var marker = {
                  lat: Number(this.locationObjectMain.geo_lat),
                  lng: Number(this.locationObjectMain.geo_long),
                };
                this.markers.push({ position: marker });
              }
            }
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    validate_edit_location() {
      if (this.$refs.form.validate()) {
        this.locationLoading = true;
        this.edit_location();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Enter Valid Inputs",
        };
      }
    },
    async edit_location() {
      this.locationLoading = true;
      try {
        var inputParam = {
          organisation_id:
            this.$store.getters.get_org_details[0].organisation_id,
          location_name: this.location_name,
          location_id: this.locationObjectMain.location_id,
          user_email_id: this.$store.getters.get_useremail,
          geo_lat: this.latt,
          geo_long: this.longg,
        };
        for (const [key, value] of Object.entries(inputParam)) {
          if (value == "") delete inputParam[key];
        }
        await API.graphql(
          graphqlOperation(EditLocations, {
            input: inputParam,
          })
        ).then((res) => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: res.data.EditLocations.Message,
          };
          this.$emit("close_edit_get_loc");
          this.locationLoading = false;
        });
      } catch (err) {
        console.log(err);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
        this.locationLoading = false;
      }
    },
    validate_add_location() {
      if (this.$refs.form.validate()) {
        this.locationLoading = true;
        this.add_location();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Enter Valid Inputs",
        };
      }
    },
    async add_location() {
      this.locationLoading = true;

      try {
        var inputParams = {
          organisation_id:
            this.$store.getters.get_org_details[0].organisation_id,
          location_name: this.location_name,
          user_email_id: this.$store.getters.get_useremail,
          geo_lat: this.latt,
          geo_long: this.longg,
        };
        for (const [key, value] of Object.entries(inputParams)) {
          if (value == "") delete inputParams[key];
        }
        await API.graphql(
          graphqlOperation(CreateLocations, {
            input: inputParams,
          })
        ).then((res) => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: res.data.CreateLocations.Message,
          };
          // this.$emit("close_location_dialog");
          this.conf_open_src_dialog(res.data.CreateLocations.location_id);
          // this.close_dialog_check_for_source(
          //   res.data.CreateLocations.location_id,
          //   res.data.CreateLocations.location_name
          // );
          this.locationLoading = false;
          this.$refs.form.reset();
          this.$emit("clicked", 0);
        });
      } catch (err) {
        console.log(err);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
        this.locationLoading = false;
      }
    },
    async conf_open_src_dialog(location_id) {
      this.src_loading = true;
      try {
        var inputParams = {
          organisation_id:
            this.$store.getters.get_org_details[0].organisation_id,
          qr_location_name: this.location_name,
          user_email_id: this.$store.getters.get_useremail,
          location_id: location_id,
          qr_direction: "NEUTRAL",
        };
        for (const [key, value] of Object.entries(inputParams)) {
          if (value == "") delete inputParams[key];
        }
        await API.graphql(
          graphqlOperation(AddQrToLocations, {
            input: inputParams,
          })
        ).then((res) => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: res.data.AddQrToLocations.Message,
          };
          this.src_loading = false;
          this.deleteLoading = false;
          this.confDeleteDialog = false;
          this.$emit("success_conf_for_soucre");
        });
      } catch (err) {
        console.log(err);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
        this.src_loading = false;
      }
    },
    async GetCurrentLocation() {
      // this.cardLoading = true;
      // this.markers = [];

      // navigator.geolocation.getCurrentPosition(
      //   (pos) => {
      //     const marker = {
      //       lat: pos.coords.latitude,
      //       lng: pos.coords.longitude,
      //     };
      //     this.markers.push({ position: marker });
      //     this.latt = marker.lat;
      //     this.longg = marker.lng;

      // Do something with 'latt' and 'longg' here, e.g., update UI or make API calls.

      //     this.cardLoading = false;
      //   },
      //   (error) => {
      //     console.error("Error while getting current location:", error);
      //     this.cardLoading = false; // Set cardLoading to false in case of an error.
      //   }
      // );
      //---------------------------------------//
      this.cardLoading = true;

      try {
        this.markers = [];

        let crntLoc = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            function (pos) {
              let marker = {
                lat: pos.coords.latitude,
                lng: pos.coords.longitude,
              };
              resolve({ position: marker });
            },
            function (error) {
              // Handle geolocation errors here
              reject(error);
            }
          );
        });

        this.markers.push(crntLoc);
        this.latt = crntLoc.position.lat;
        this.longg = crntLoc.position.lng;

        // console.log(this.markers);

        // Do something with 'latt' and 'longg' here, e.g., update UI or make API calls.

        // Set 'cardLoading' to false when the location is retrieved and processed.
        this.cardLoading = false;

        // You can return the current location object if needed.
      } catch (error) {
        // Handle any errors that might occur during the process.
        console.error("Error while getting current location:", error);
        this.cardLoading = false; // Set cardLoading to false in case of an error.
      }
      //---------------------------------------//
      // var self = this;
      // self.markers = [];
      // let crntLoc = await new Promise((resolve) => {
      //   navigator.geolocation.getCurrentPosition(function (pos) {
      //     var marker = {
      //       lat: pos.coords.latitude,
      //       lng: pos.coords.longitude,
      //     };
      //     resolve({ position: marker });
      //   });
      // });
      // self.markers.push(crntLoc);
      // self.latt = crntLoc.position.lat;
      // self.longg = crntLoc.position.lng;
      // console.log(self.markers);
      // this.cardLoading = false;
    },
    callposition(latLng) {
      this.markers = [];
      var marker = {
        lat: latLng.latLng.lat(),
        lng: latLng.latLng.lng(),
      };
      this.markers.push({ position: marker });
      (this.latt = marker.lat), (this.longg = marker.lng);
    },
    setPlace(place) {
      this.currentPlace = place;
    },
    addMarker() {
      if (this.currentPlace) {
        this.complete_address = this.currentPlace.formatted_address;
        this.markers = [];
        var marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers.push({ position: marker });
        (this.latt = marker.lat), (this.longg = marker.lng);
      }
    },
    // close_dialog_check_for_source(location_id, location_name) {
    //   this.$refs.form.reset();
    //   this.location_name = "";
    //   this.latt = "";
    //   this.longg = "";
    //   this.$emit("close_location_dialog_check_src", location_id, location_name);
    //   this.radioGroup = "";
    // },
    close_dialog() {
      this.$refs.form.reset();
      this.location_name = "";
      this.latt = "";
      this.longg = "";
      this.$emit("close_location_dialog");
      this.radioGroup = "";
    },
  },
};
</script>

<style></style>
