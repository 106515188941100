<template>
  <div>
    <!--  eslint-disable -->
    <v-card flat>
      <v-row no-gutters class="mt-5">
        <v-col
          class="mt-3"
          v-if="$vuetify.breakpoint.name != 'xs'"
          cols="12"
          md="12"
          sm="12"
          lg="12"
          xl="12"
        >
          <v-toolbar dense class="elevation-0 ml-2">
            <v-select
              v-model="locationFilter"
              label="Filter by Location"
              dense
              :items="locationFilterItems"
              item-text="text"
              :disabled="isLoadingLocations == true"
              item-value="value"
              style="max-width: 170px"
              @change="list_locations(locationFilter)"
              class="ml-n2"
              outlined
            ></v-select>
            <v-text-field
              v-model="search"
              placeholder="Min 3 Character"
              label="Search"
              style="max-width: 220px"
              append-icon="mdi-magnify"
              class="ml-2"
            ></v-text-field>
            <v-spacer />
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#D97D54"
                  class="gradient-bg white--text mt-n6"
                  text
                  small
                  v-on="on"
                  v-bind="attrs"
                  depressed
                  @click="refersh()"
                >
                  <v-icon class="pr-4">mdi-refresh</v-icon> Refresh
                </v-btn>
              </template>
              <span class="white--text">Refresh</span>
            </v-tooltip>
            <v-btn
              v-if="locationFilter == 'ACTIVE'"
              small
              depressed
              @click="open_location()"
              class="gradient-bg white--text mt-n6 ml-4"
            >
              <v-icon class="mr-2"> mdi-plus</v-icon
              ><span
                v-text="$vuetify.breakpoint.name == 'xs' ? '' : 'Add Location'"
              ></span>
            </v-btn>
          </v-toolbar>
        </v-col>

        <v-col v-if="$vuetify.breakpoint.name == 'xs'" cols="12" md="12">
          <v-toolbar dense class="elevation-0">
            <v-select
              v-model="locationFilter"
              label="Filter by Location"
              dense
              :items="locationFilterItems"
              item-text="text"
              item-value="value"
              @change="list_locations(locationFilter)"
              class="mr-2"
              outlined
            ></v-select>

            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              class="mr-2"
            ></v-text-field>
          </v-toolbar>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.name == 'xs'" cols="12" md="12">
          <v-toolbar dense class="elevation-0">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#D97D54"
                  class="gradient-bg white--text mt-n6"
                  text
                  small
                  v-on="on"
                  v-bind="attrs"
                  depressed
                  @click="list_locations(locationFilter)"
                >
                  <v-icon class="pr-4">mdi-refresh</v-icon> Refresh
                </v-btn>
              </template>
              <span class="white--text">Refresh</span>
            </v-tooltip>

            <v-btn
              small
              depressed
              @click="open_location()"
              class="gradient-bg white--text mt-n6 ml-4"
            >
              <v-icon class="mr-2">mdi-plus</v-icon
              ><span
                v-text="$vuetify.breakpoint.name == 'xs' ? '' : 'Add Location'"
              ></span>
            </v-btn>
          </v-toolbar>
        </v-col>
      </v-row>

      <v-card-text>
        <v-data-table
          :height="height"
          :headers="locationData"
          :items="locationItems"
          :loading="isLoadingLocations"
          dense
          loading-text="Loading... Please wait"
          :search="search"
          :fixed-header="fixed"
          :options.sync="pagination"
          @update:options="get_location_Pagination(locationFilter)"
          class="overflow-y-auto overflow-x-auto"
          :footer-props="{
            'items-per-page-options': [10, 15, 20, 25],
          }"
        >
          <template v-slot:[`item.location_name`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <div class="overflow" v-on="on">
                  {{ item.location_name }}
                </div>
              </template>
              <span class="white--text">{{ item.location_name }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.location_created_on`]="{ item }">
            <span
              v-text="get_date(item.location_created_on).split(',')[0]"
            ></span>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No records found!
            </v-alert>
          </template>

          <template v-slot:[`item.locationslist`]="{ item }">
            <span v-if="item.locationslist == 'MAIN_LOCATION'">
              Member Location</span
            >
            <span v-if="item.locationslist == 'SCAN_ANY_WHERE'">
              Scan Any Where</span
            >
            <span v-if="item.locationslist == 'VISITOR_LOCATION'">
              Visitor Location</span
            >
          </template>

          <template v-slot:[`item.no_of_source`]="{ item }">
            <div v-if="item.location_status == 'ACTIVE'">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="gradient-bg white--text cursor"
                    x-small
                    v-show="
                      item.location_source_type == 'MAIN_LOCATION' &&
                      item.no_of_source > 1
                    "
                    v-on="on"
                    @click="view_src_location(item)"
                    >view</v-btn
                  >
                  <v-icon
                    class="primary--text cursor ml-2"
                    v-show="
                      item.location_source_type == 'MAIN_LOCATION' &&
                      item.no_of_source == 1
                    "
                    v-on="on"
                    @click="view_qr(item)"
                    >mdi-qrcode
                  </v-icon>
                  <span
                    class="ml-3 caption"
                    v-show="
                      item.location_source_type == 'MAIN_LOCATION' &&
                      item.no_of_source == 0
                    "
                    >N/A</span
                  >

                  <v-icon
                    class="primary--text cursor ml-2"
                    v-show="item.location_source_type == 'VISITOR_LOCATION'"
                    @click="view_visitor_location(item)"
                    v-on="on"
                    >mdi-qrcode
                  </v-icon>
                  <span
                    v-show="item.location_source_type == 'SCAN_ANY_WHERE'"
                    class="ml-3 caption"
                    >NA</span
                  >
                </template>

                <span class="white--text"> view </span>
              </v-tooltip>
            </div>
            <div
              v-if="
                item.location_status != 'ACTIVE' &&
                item.location_source_type != 'N/A'
              "
            >
              <span class="ml-3 caption">N/A</span>
            </div>
          </template>

          <template v-slot:[`item.location_source_type`]="{ item }">
            <div v-if="item.location_status == 'ACTIVE'">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="ViewThisLocation(item)"
                    color="primary"
                    small
                    v-if="
                      item.location_source_type == 'MAIN_LOCATION' &&
                      item.location_source_type != 'NA'
                    "
                    v-on="on"
                    v-bind="attrs"
                    >mdi-map-marker-radius</v-icon
                  >

                  <v-icon color="grey" small v-on="on" v-bind="attrs" v-else
                    >mdi-map-marker-off</v-icon
                  >
                </template>
                <span
                  v-if="
                    item.location_source_type == 'MAIN_LOCATION' &&
                    item.location_source_type != 'NA'
                  "
                  class="white--text"
                  >View Geo</span
                >
                <span v-else class="white--text">Geo Not Enabled </span>
              </v-tooltip>
            </div>
            <div v-if="item.location_status != 'ACTIVE'">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="grey" small v-on="on" v-bind="attrs"
                    >mdi-map-marker-off</v-icon
                  >
                </template>
                <span class="white--text">Geo Not Enabled</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <div v-if="item.location_status == 'ACTIVE'">
              <v-row>
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <v-icon
                        @click="view_locationtable(item)"
                        v-on="on"
                        :disabled="
                          item.location_source_type == 'SCAN_ANY_WHERE'
                        "
                        small
                        color="primary"
                        class="ml-n2 mr-1"
                        >mdi-card-account-details-outline</v-icon
                      >
                    </div>
                  </template>
                  <span
                    class="white--text"
                    v-if="item.location_source_type == 'SCAN_ANY_WHERE'"
                    >Cannot Send Scan Anywhere Location attached QR</span
                  >
                  <span
                    class="white--text"
                    v-else-if="item.location_source_type == 'VISITOR_LOCATION'"
                  >
                    Send visitor Location attached QR</span
                  >
                  <span class="white--text" v-else
                    >Send Primary Location attached QR</span
                  >
                </v-tooltip>
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <v-icon
                        @click="edit_locations(item, 'EDIT')"
                        class=""
                        small
                        :disabled="
                          item.location_source_type == 'SCAN_ANY_WHERE'
                        "
                        color="primary"
                        v-on="on"
                        >mdi-pencil
                      </v-icon>
                    </div>
                  </template>
                  <span
                    class="white--text"
                    v-if="item.location_source_type == 'SCAN_ANY_WHERE'"
                    >Cannot Edit Scan Anywhere Location</span
                  >
                  <span
                    class="white--text"
                    v-else-if="item.location_source_type == 'VISITOR_LOCATION'"
                  >
                    Edit visitor Location</span
                  >
                  <span class="white--text" v-else> Edit Primary Location</span>
                </v-tooltip>

                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <v-btn
                        @click="add_src_for_location(item)"
                        v-on="on"
                        :disabled="item.location_source_type != 'MAIN_LOCATION'"
                        x-small
                        rectangle
                        class="gradient-bg white--text ml-1"
                        ><v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                  </template>

                  <span
                    class="white--text"
                    v-if="item.location_source_type == 'VISITOR_LOCATION'"
                    >Cannot Add Visitor Source</span
                  >
                  <span
                    class="white--text"
                    v-else-if="item.location_source_type != 'MAIN_LOCATION'"
                    >Cannot Add Scan AnyWhere Source</span
                  >
                  <span class="white--text" v-else>Add Primary Source </span>
                </v-tooltip>

                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <v-icon
                        @click="delete_location_details(item)"
                        small
                        class="ml-1"
                        color="primary"
                        :disabled="
                          item.location_source_type == 'SCAN_ANY_WHERE' ||
                          item.is_primary == true
                        "
                        v-on="on"
                        >mdi-delete
                      </v-icon>
                    </div>
                  </template>
                  <span
                    class="white--text"
                    v-if="
                      item.location_source_type == 'VISITOR_LOCATION' &&
                      item.is_primary != true &&
                      item.location_source_type != 'NA'
                    "
                    >{{ "De-Activate Visitor Location" }}</span
                  >
                  <span
                    class="white--text"
                    v-else-if="
                      item.location_source_type == 'SCAN_ANY_WHERE' &&
                      item.is_primary != true &&
                      item.location_source_type != 'NA'
                    "
                    >{{ "Cannot De-Activate Scan Anywhere Location" }}</span
                  >
                  <span
                    class="white--text"
                    v-else-if="
                      item.location_source_type == 'SCAN_ANY_WHERE' ||
                      (item.is_primary == true &&
                        item.location_source_type != 'NA')
                    "
                    >{{ "Cannot De-Activate Primary Location" }}</span
                  >
                  <span class="white--text" v-else>{{
                    "De-Activate Location"
                  }}</span>
                </v-tooltip>
              </v-row>
            </div>

            <div v-else>
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-icon
                    @click="delete_location_details(item)"
                    small
                    class="ml-5"
                    color="primary"
                    v-on="on"
                    >mdi-checkbox-multiple-marked-circle
                  </v-icon>
                </template>
                <span class="white--text">{{ "Re-Activate Location" }}</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div v-if="componeneCheck == 1">
      <AddLocationPhysical
        :addLocationDialog="addLocationDialog"
        @close_location_dialog="close_physical"
        :locationObjectMain="locationObjectMain"
        @close_edit_get_loc="get_loc_close_edit"
        @clicked="close_popus"
      />
      <!-- @close_location_dialog_check_src="check_src_location" -->
    </div>
    <AddLocationVisitor
      :addLocationVisitorDialog="addLocationVisitorDialog"
      @close_visitor_location_dialog="close_Visitor"
      :locationObjectMain="locationObjectMain"
      @close_edit_visitor_get_loc="get_loc_close_edit"
    />
    <AddSourceForLocation
      :addSourceLocationDialog="addSourceLocationDialog"
      @close_src_location_dialog="addSourceLocationDialog = false"
      :send_LocationId="send_LocationId"
      :SourceObject="SourceObject"
      :send_LocationName="send_LocationName"
      @close_src_loc_get_location="get_location"
    />
    <Overlay :overlay="overlay" />
    <!-- <CheckForSourceLocation
      :confSrcDialog="confSrcDialog"
      :message="message"
      :texticon="texticon"
      :textcolor="textcolor"
      :srcLoading="srcLoading"
      :LocationId="LocationId"
      :LocationName="LocationName"
      @close_conf_delete_dialog="confSrcDialog = false"
      @success_conf_for_soucre="get_location"
    /> -->
    <ViewGeoSwipes
      :viewGeoSwipesDialog="viewGeoSwipesDialog"
      @close_view_geo_swipes_dialog="viewGeoSwipesDialog = false"
      :userObject="userObject"
      :swipeData="swipeData"
    />
    <ViewSourcesForLocation
      :locationObject="locationObject"
      :viewSourceDialog="viewSourceDialog"
      @close_view_src_dialog="viewSourceDialog = false"
      @get_locations="get_location_open_view_src"
    />
    <DeleteDialog
      :confDeleteDialog="confDeleteDialog"
      @close_conf_delete_dialog="confDeleteDialog = false"
      :deleteText="deleteText"
      :deleteIcon="deleteIcon"
      :deleteColor="deleteColor"
      @success_conf_delete="deleteSuccessAction"
      :deleteLoading="deleteLoading"
    />
    <ViewVisitorQR
      :viewVisitorQRDialog="viewVisitorQRDialog"
      @close_view_qr_dialog="viewVisitorQRDialog = false"
      :visitorObject="visitorObject"
    />
    <AddLocationPrompt
      :addLocationPromptDialog="addLocationPromptDialog"
      :locationObjectMain="locationObjectMain"
      @clickedPhysical="open_Physical"
      @clickedVisitor="open_Visitor"
      @clicked="close_prompt"
    />
    <LocationPrint
      @close_viewlocationsstable_dialog="viewlocationtable = false"
      :locationObjectMain="locationObjectMain"
      :viewlocationtable="viewlocationtable"
      @get_locationqr="get_location_src"
    />
    <div v-if="componeneCheck == 2">
      <ViewLocationQR
        :viewMemberQRDialog="viewMemberQRDialog"
        @close_view_qr_dialog="viewMemberQRDialog = false"
        :locationObjectMain="locationObjectMain"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import LocationPrint from "@/components/Dialogs/LocationPrint.vue";
import ViewGeoSwipes from "@/components/Dialogs/ViewGeoSwipes.vue";
import { API, graphqlOperation } from "aws-amplify";
import { DeleteOrganisationsLocation } from "@/graphql/mutations.js";
import { GetLocationsAccordingToStatus } from "@/graphql/queries.js";
import AddLocationPhysical from "@/components/Dialogs/AddLocationPhysical.vue";
import AddLocationVisitor from "@/components/Dialogs/AddLocationVisitor.vue";
import AddSourceForLocation from "@/components/Dialogs/AddSourceForLocation.vue";
import Overlay from "@/components/Overlay.vue";
// import CheckForSourceLocation from "@/components/Dialogs/ConfirmationDialogs/CheckForSourceLocation.vue";
import ViewSourcesForLocation from "@/components/Dialogs/ViewSourcesForLocation.vue";
import DeleteDialog from "@/components/Dialogs/ConfirmationDialogs/DeleteDialog.vue";
import ViewVisitorQR from "@/components/Dialogs/ViewVisitorQR.vue";
import AddLocationPrompt from "@/components/Dialogs/AddLocationPrompt.vue";
import ViewLocationQR from "@/components/Dialogs/ViewLocationQR.vue";

export default {
  components: {
    ViewLocationQR,
    AddLocationPhysical,
    AddLocationVisitor,
    Overlay,
    AddSourceForLocation,
    // CheckForSourceLocation,
    ViewGeoSwipes,
    ViewSourcesForLocation,
    DeleteDialog,
    ViewVisitorQR,
    LocationPrint,
    AddLocationPrompt,
  },
  data: () => ({
    deleteLoading: false,
    componeneCheck: 0,
    senddetails: false,
    viewMemberQRDialog: false,
    confDeleteDialog: false,
    addLocationVisitorDialog: false,
    deleteText: "",
    fixed: true,
    location_name: "test location",
    locationObject: {},
    visitorObject: {},
    viewSourceDialog: false,
    viewlocationtable: false,
    send_LocationId: "",
    srcLoading: false,
    message: "",
    userObject: {},
    swipeData: [],
    texticon: "",
    viewVisitorQRDialog: false,
    viewGeoSwipesDialog: false,
    next_token: null,
    pagination: {
      itemsPerPage: 25,
      page: 1,
    },
    // itemsPerPage: 15,
    textcolor: "",
    isLoadingLocations: false,
    addSourceLocationDialog: false,
    // confSrcDialog: false,
    fixed: true,
    overlay: false,
    addLocationDialog: false,
    addLocationPromptDialog: false,
    locationData: [
      { text: "Geo", value: "location_source_type" },
      { text: "Location Name", value: "location_name" },
      { text: "#Sources", value: "no_of_source", sortable: false },
      { text: "Created On", value: "location_created_on" },
      { text: "Created By", value: "location_created_by" },
      { text: "Location", value: "locationslist" },
      { text: "Actions", value: "Actions", sortable: false },
    ],
    locationItems: [],
    locationFilterItems: [
      { text: "ACTIVE", value: "ACTIVE" },
      { text: "INACTIVE", value: "INACTIVE" },
    ],
    locationFilter: "ACTIVE",
    search: "",
    LocationName: "",
    send_LocationName: "",
    locationObjectMain: {},
    SourceObject: {},
    locaiondelete: false,
    pageCount: 0,
    deleteIcon: "",
    deleteColor: "",
    LocationId: "",
    arraylist: [],
    height: 0,
  }),
  created() {
    this.width = window.innerWidth - 280;
    this.height = window.innerHeight - 220;
    this.overlay = true;
    this.isLoadingLocations = true;
  },
  mounted() {
    this.list_locations(this.locationFilter);
  },
  watch: {
    // location(val) {
    //   console.log(val);
    // },
    search() {
      if (this.search != "") {
        if (this.search.length >= 3) {
          // console.log(this.search);
          this.list_locations();
        }
      } else {
        this.list_locations();
      }
    },
  },
  methods: {
    popup(val) {
      if (val == 5) {
        this.$store.commit("snackbar/showMessage", {
          content: "Qr not Generated",
          color: "red",
        });
      }
    },
    refersh() {
      if (this.locationFilter == "ACTIVE") {
        this.search = "";
        this.list_locations("ACTIVE");
      } else if (this.locationFilter == "INACTIVE") {
        this.list_locations("DEACTIVE");
      }
    },
    view_qr(item) {
      this.componeneCheck = 2;
      this.locationObjectMain = item;
      this.viewMemberQRDialog = true;
    },
    delete_(item) {
      this.locationObjectMain = item;
      this.locaiondelete = true;
    },
    close_popus(val) {
      if (val == 0) {
        this.get_location();
        this.addLocationDialog = false;
        this.locaiondelete = false;
      }
    },
    add_src_for_location(item) {
      this.SourceObject = {
        item: null,
      };
      // console.log("Location ID", item);
      this.send_LocationId = item.location_id;
      this.send_LocationName = item.location_name;
      // console.log(" this.send_LocationId ", this.send_LocationId);
      // console.log(" this.send_LocationName ", this.send_LocationName);
      this.action = "CREATE";

      setTimeout(() => {
        this.addSourceLocationDialog = true;
      }, 100);
    },

    get_location_open_view_src() {
      this.get_location();
      this.viewSourceDialog = true;
    },
    get_date(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    delete_location_details(item) {
      this.deleteUserItem = item;
      this.confDeleteDialog = true;
      this.deleteText =
        item.location_status == "ACTIVE"
          ? `Are you sure you want to De-Activate the Location ${item.location_name} ?`
          : `Are you sure you want to Re-Activate the Location ${item.location_name}`;
      this.deleteIcon =
        item.location_status == "ACTIVE"
          ? "mdi-account-off"
          : "mdi-account-check";
      this.deleteColor = this.location_status == "ACTIVE" ? "red" : "green";
    },
    deleteSuccessAction() {
      this.deleteLoading = true;
      this.deactivate_reactivate_location(this.deleteUserItem);
    },
    async deactivate_reactivate_location(item) {
      this.deleteLoading = true;
      try {
        await API.graphql(
          graphqlOperation(DeleteOrganisationsLocation, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              action_type: item.location_status == "ACTIVE" ? "DEACTIVE" : "",
              location_id: item.location_id,
            },
          })
        ).then((res) => {
          this.$store.commit("snackbar/showMessage", {
            content: res.data.DeleteOrganisationsLocation.Message,
            color: "green",
          });

          this.deleteLoading = false;
          this.confDeleteDialog = false;
          this.get_location();
        });
      } catch (err) {
        this.$store.commit("snackbar/showMessage", {
          content: err.errors[0].message,
          color: "red",
        });
        this.deleteLoading = false;
      }
    },
    get_loc_close_edit() {
      this.addLocationDialog = false;
      this.addLocationVisitorDialog = false;
      this.get_location();
    },
    open_location() {
      this.componeneCheck = 1;
      this.locationObjectMain.actionObject = "ADD";
      this.addLocationDialog = true;
      // this.addLocationPromptDialog = true;
    },

    open_Physical() {
      this.addLocationPromptDialog = false;
      this.locationObjectMain.actionObject = "ADD";
      this.addLocationDialog = true;
    },
    open_Visitor() {
      this.addLocationPromptDialog = false;
      this.locationObjectMain.actionObject = "ADD";
      this.addLocationVisitorDialog = true;
    },
    view_locationtable(item) {
      this.locationObjectMain = item;
      this.viewlocationtable = true;
    },
    get_location_src() {
      this.viewlocationtable = false;
    },

    edit_locations(item, action) {
      this.locationObjectMain = item;
      this.componeneCheck = 1;
      this.locationObjectMain.actionObject = action;
      if (this.locationObjectMain.location_source_type == "MAIN_LOCATION") {
        this.addLocationDialog = true;
      } else {
        this.addLocationVisitorDialog = true;
      }
    },
    close_prompt() {
      this.addLocationPromptDialog = false;
    },
    view_src_location(item) {
      this.locationObject = item;
      this.viewSourceDialog = true;
    },
    view_visitor_location(item) {
      this.visitorObject = item;
      this.viewVisitorQRDialog = true;
    },
    ViewThisLocation(item) {
      this.userObject = item;
      this.swipeData = [];
      this.swipeData.push({
        position: {
          lat: Number(
            item.user_lat != undefined ? item.user_lat : item.geo_lat
          ),
          lng: Number(
            item.user_long != undefined ? item.user_lat : item.geo_long
          ),
        },
      });
      this.viewGeoSwipesDialog = true;
    },
    // open_src__location_dialog() {
    //   this.send_LocationId = this.LocationId;
    //   this.send_LocationName = this.LocationName;
    //   this.confSrcDialog = false;
    //   this.addSourceLocationDialog = true;
    // },
    // check_src_location(location_id, location_name) {
    //   this.get_location();
    //   setTimeout(() => this.open_srcDialog(location_id, location_name), 210);
    // },
    // open_srcDialog(location_id, location_name) {
    //   this.LocationName = location_name;
    //   this.LocationId = location_id;
    //   this.confSrcDialog = true;
    //   this.message = `Do you want to add a Source for the location ${location_name} ?`;
    //   this.texticon = "mdi-qrcode";
    //   this.textcolor = "green";
    // },
    close_physical() {
      this.addLocationDialog = false;
      this.get_location();
    },
    close_Visitor() {
      this.addLocationVisitorDialog = false;
      this.get_location();
    },
    get_location() {
      this.addLocationDialog = false;
      // this.confSrcDialog = false;
      this.addSourceLocationDialog = false;
      if (this.locationFilter == "ACTIVE") {
        setTimeout(() => {
          this.list_locations("ACTIVE");
        }, 500);
      } else {
        setTimeout(() => {
          this.list_locations("DEACTIVE");
        }, 500);
      }
    },
    async list_locations(locationFilter) {
      this.overlay = true;
      this.isLoadingLocations = true;
      // this.pageCount = 0;
      // console.log(locationFilter, " filter");

      try {
        await API.graphql(
          graphqlOperation(GetLocationsAccordingToStatus, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              location_status:
                this.locationFilter == "ACTIVE" ? "ACTIVE" : "DEACTIVE",
              nextToken: null,
              location_name: this.search,
              limit: 101,
            },
          })
        ).then((res) => {
          this.locationItems =
            res.data.GetLocationsAccordingToStatus.data != null
              ? res.data.GetLocationsAccordingToStatus.data.Items
              : [];
          this.next_token = res.data.GetLocationsAccordingToStatus.nextToken;
          // this.locationItems.locationslist=this.locationItems.location_source_type
          for (let i = 0; i < this.locationItems.length; i++) {
            this.locationItems[i].locationslist =
              this.locationItems[i].location_source_type;
          }

          // console.log(this.locationItems);
          // console.log(res.data.GetLocationsAccordingToStatus.nextToken);
          this.overlay = false;
          this.isLoadingLocations = false;
          this.$forceUpdate();
        });
      } catch (err) {
        this.locationItems = [];
        this.overlay = false;
        this.isLoadingLocations = false;
      }
    },
    async get_location_Pagination(locationFilter) {
      this.overlay = true;
      this.isLoadingLocations = true;
      // this.pageCount = this.pageCount + 1;
      // console.log(locationFilter, "pagination filter");
      // console.log(this.next_token, "next_tokemn");

      try {
        if (this.next_token) {
          await API.graphql(
            graphqlOperation(GetLocationsAccordingToStatus, {
              input: {
                organisation_id:
                  this.$store.getters.get_org_details[0].organisation_id,
                location_status:
                  locationFilter == "ACTIVE" ? "ACTIVE" : "DEACTIVE",
                nextToken: this.next_token,
                limit: 101,
              },
            })
          ).then((res) => {
            let locc =
              res.data.GetLocationsAccordingToStatus.data != null
                ? res.data.GetLocationsAccordingToStatus.data.Items
                : [];
            if (locc.length > 0) {
              // var response = res.data.GetLocationsAccordingToStatus.data.Items;
              // this.locationItems.concat(response);
              this.locationItems = this.locationItems.concat(
                res.data.GetLocationsAccordingToStatus.data.Items
              );
              this.next_token =
                res.data.GetLocationsAccordingToStatus.nextToken;
              this.overlay = false;
              this.isLoadingLocations = false;
              this.$forceUpdate();
            }
          });
        } else {
          this.overlay = false;
          this.isLoadingLocations = false;
        }
      } catch (err) {
        this.locationItems = [];
        this.overlay = false;
        this.isLoadingLocations = false;
      }
    },
  },
};
</script>
<style>
/* .v-application--is-ltr .v-data-footer__pagination {
  margin-left: auto;
} */
</style>
