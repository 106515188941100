<template>
  <div>
    <v-dialog v-model="viewSourceDialog" persistent width="700px">
      <v-toolbar dense class="gradient-bg elevation-0">
        <v-toolbar-title class="white--text"
          >View Sources for Locations-
          {{ locationObject.location_name }}</v-toolbar-title
        >
        <v-spacer />

        <v-icon @click="close_dialog()" class="white--text mr-n3"
          >mdi-close</v-icon
        >
      </v-toolbar>
      <v-card class="overflow-hidden">
        <v-row no-gutters class="mx-3 mt-3">
          <v-spacer />

          <!-- 
        <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
              <v-btn
                @click="add_src_for_location()"
                v-on="on"
                small
                class="gradient-bg white--text"
                text
                ><v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span class="white--text">Add Source</span>
          </v-tooltip> -->
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="12" sm="12" lg="12" xl="12">
            <v-data-table
              :headers="
                locationObject.location_name == 'SCAN_ANY_WHERE'
                  ? headersInfo
                  : viewSourceData
              "
              :items="viewSourceItems"
              :loading="isLoading"
              loading-text="Loading... Please wait"
              dense
              class="overflow"
              :items-per-page="5"
            >
              <!--   v-if="'VISITOR_LOCATION'== item.location_source_type "> -->
              <!--  :headers=" locationObject.location_name == 'SCAN_ANY_WHERE' ? headersInfo: viewSourceData" -->
              <template v-slot:[`item.sourceType`]="{ item }">
                <!-- <v-icon
                  small
                  class="ml-5"
                  v-text="
                    item.location_source_type == 'BOX'
                      ? 'mdi-account-credit-card'
                      : item.location_source_type == 'BEACON'
                      ? 'mdi-alpha-b-box-outline'
                      : item.location_source_type == 'RFID'
                      ? 'mdi-card-account-details-outline'
                      : 'mdi-qrcode-scan'
                  "
                ></v-icon> -->
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      class="ml-5"
                      v-on="on"
                      v-text="
                        item.location_source_type == 'BOX'
                          ? 'mdi-account-credit-card'
                          : item.location_source_type == 'BEACON'
                          ? 'mdi-alpha-b-box-outline'
                          : item.location_source_type == 'RFID'
                          ? 'mdi-card-account-details-outline'
                          : 'mdi-qrcode-scan'
                      "
                    >
                    </v-icon>
                  </template>
                  <span
                    class="white--text"
                    v-text="
                      item.location_source_type == 'BOX'
                        ? 'Loaction'
                        : item.location_source_type == 'BEACON'
                        ? 'Beacon'
                        : item.location_source_type == 'RFID'
                        ? 'RFID'
                        : 'QR'
                    "
                  ></span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.qr_location_name`]="{ item }">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <div class="overflow" v-on="on">
                      {{ item.qr_location_name }}
                    </div>
                  </template>
                  <span class="white--text">{{ item.qr_location_name }}</span>
                </v-tooltip>
              </template>

              <!-- <template v-slot:[`no-data`]>
                <v-alert class="gradient-bg2 white--text"
                  >No Source(s) available for this Location
                </v-alert>
              </template> -->

              <template v-slot:[`no-data`]>
                <v-alert class="gradient-bg2 white--text"
                  >ADD SOURCE FOR LOCATION
                </v-alert>
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="add_src_for_location()"
                      v-on="on"
                      small
                      class="primary white--text mb-2"
                      text
                    >
                      <span>add source</span>
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span class="white--text">Add Source</span>
                </v-tooltip>
              </template>

              <template v-slot:[`item.view_qr`]="{ item }">
                <v-tooltip
                  v-if="!['BEACON', 'BOX'].includes(item.location_source_type)"
                  bottom
                  color="primary"
                >
                  <template v-slot:activator="{ on }">
                    <a @click="view_qr(item)">
                      <v-icon v-on="on" small class="primary--text mr-1"
                        >mdi-qrcode
                      </v-icon>
                    </a>
                  </template>
                  <span class="white--text">View QR</span>
                </v-tooltip>
                <div v-else>N/A</div>
              </template>
              <template v-slot:[`item.url`]="{ item }">
                <v-tooltip
                  v-if="!['BEACON', 'BOX'].includes(item.location_source_type)"
                  bottom
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      @click="doCopy()"
                      v-clipboard:copy="
                        `https://www.prznce.com/QR/#/${item.qr_id}`
                      "
                      v-on="on"
                      v-bind="attrs"
                      color="primary"
                      >mdi-content-copy</v-icon
                    >
                  </template>
                  <span class="white--text">{{
                    `https://www.prznce.com/QR/#/${item.qr_id}`
                  }}</span>
                </v-tooltip>
                <div v-else>N/A</div>
              </template>
              <!--  <template v-slot:[`item.Actions`]="{ item }">-->
              <template v-slot:[`item.Actions`]="{}">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="add_src_for_location()"
                      color="primary"
                      v-on="on"
                      v-bind="attrs"
                      small
                      >mdi-pencil</v-icon
                    >
                  </template>
                  <!-- <span class="white--text"> Edit Source</span> -->
                  <span class="white--text">Add Source</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <Overlay :overlay="overlay" />
    <AddSourceForLocation
      :action="action"
      :send_LocationId="send_LocationId"
      :send_LocationName="send_LocationName"
      :addSourceLocationDialog="addSourceLocationDialog"
      @close_src_location_dialog="addSourceLocationDialog = false"
      :SourceObject="SourceObject"
      @close_src_loc_get_src="get_src"
      @close_src_loc_get_location="get_location"
    />
    <ViewLocationMemberQR
      :viewMemberQRDialog="viewMemberQRDialog"
      @close_view_qr_dialog="viewMemberQRDialog = false"
      :userDetailsObj="userDetailsObj"
    />
  </div>
</template>

<script src="vue.min.js"></script>
<script src="dist/vue-clipboard.min.js"></script>
<script>
import { API, graphqlOperation } from "aws-amplify";
import { GetSourcesTaggedToLocations } from "@/graphql/queries.js";
import Overlay from "@/components/Overlay.vue";
import AddSourceForLocation from "@/components/Dialogs/AddSourceForLocation.vue";
import ViewLocationMemberQR from "@/components/Dialogs/ViewLocationMemberQR.vue";

export default {
  components: { Overlay, AddSourceForLocation, ViewLocationMemberQR },
  props: {
    viewSourceDialog: Boolean,
    locationObject: Object,
  },
  data: () => ({
    action: "",
    viewMemberQRDialog: false,
    userDetailsObj: {},
    overlay: false,
    addSourceLocationDialog: false,
    send_LocationName: "",
    send_LocationId: "",
    isLoading: false,
    SourceObject: {},
    viewSourceData: [
      { text: "", value: "sourceType" },
      { text: "Source Name", value: "qr_location_name" },
      { text: "Direction", value: "location_direction" },
      { text: "View QR", value: "view_qr" },
      // { text: "Copy URL", value: "url" },
      // { text: "Actions", value: "Actions" },
    ],
    headersInfo: [
      { text: "", value: " " },
      //  { text: "Actions", value: "Actions" },
      // { text: "View QR", value: "view_qr" },
      //  { text: " ", value: " " },
      // { text: "Actions", value: " " },
    ],
    viewSourceItems: [],
  }),
  watch: {
    viewSourceDialog(val) {
      if (val == true) {
        this.list_sources_locations();
      }
    },
  },
  methods: {
    add_src_for_location() {
      this.SourceObject = {
        item: null,
      };
      this.send_LocationId = this.locationObject.location_id;
      this.send_LocationName = this.locationObject.location_name;
      // console.log(" this.send_LocationId ", this.send_LocationId);
      // console.log(" this.send_LocationName ", this.send_LocationName);
      this.action = "CREATE";
      //this.close_dialog();
      setTimeout(() => {
        this.addSourceLocationDialog = true;
      }, 100);
    },

    // add_src_for_location() {
    //   this.SourceObject = {
    //     item: null,
    //   };
    //   this.send_LocationId = this.locationObject.location_id;
    //   this.send_LocationName = this.locationObject.location_name;
    //   console.log(" this.send_LocationId ", this.send_LocationId);
    //   console.log(" this.send_LocationName ", this.send_LocationName);
    //   this.action = 'CREATE'
    //   //this.close_dialog();
    //   setTimeout(() => {
    //     this.addSourceLocationDialog = true;
    //   }, 100);
    // },

    get_src() {
      this.list_sources_locations();
      this.addSourceLocationDialog = false;
    },
    get_location() {
      this.addSourceLocationDialog = false;
      this.$emit("get_locations");
    },
    edit_source(item) {
      this.SourceObject = item;
      // console.log("ss", item);
      this.action = "UPDATE";
      this.addSourceLocationDialog = true;
    },
    async doCopy() {
      this.$store.commit("snackbar/showMessage", {
        content: "QR URL Copied Successfully!",
        color: "green",
      });
    },
    view_qr(item) {
      this.userDetailsObj = item;
      this.viewMemberQRDialog = true;
    },

    // add_src_for_location() {
    //   this.SourceObject = {
    //     item: null,
    //   };
    //   this.send_LocationId = this.locationObject.location_id;
    //   this.send_LocationName = this.locationObject.location_name;
    //   console.log(" this.send_LocationId ", this.send_LocationId);
    //   console.log(" this.send_LocationName ", this.send_LocationName);
    //   this.action = 'CREATE'
    //   this.close_dialog();
    //   setTimeout(() => {
    //     this.addSourceLocationDialog = true;
    //   }, 100);
    // },

    async list_sources_locations() {
      this.overlay = true;
      this.isLoading = true;
      try {
        await API.graphql(
          graphqlOperation(GetSourcesTaggedToLocations, {
            input: {
              location_id: this.locationObject.location_id,
            },
          })
        ).then((res) => {
          this.viewSourceItems =
            res.data.GetSourcesTaggedToLocations.data.length != 0
              ? res.data.GetSourcesTaggedToLocations.data
              : [];
          this.overlay = false;
          this.isLoading = false;
        });
      } catch (err) {
        console.log("errrrr", err);
        this.overlay = false;
        this.isLoading = false;
      }
    },
    close_dialog() {
      this.$emit("close_view_src_dialog");
    },
  },
};
</script>

<style></style>
