<template>
  <div>
    <v-dialog
      v-model="addLocationVisitorDialog"
      persistent
      width="700px"
      class="overflow-hidden"
    >
      <v-toolbar dense class="gradient-bg elevation-0">
        <v-toolbar-title
          class="white--text"
          v-text="
            locationObjectMain.actionObject == 'ADD'
              ? 'Add Visitor Location'
              : 'Edit Visitor Location'
          "
        ></v-toolbar-title>
        <v-spacer />

        <v-icon @click="close_dialog()" class="white--text mr-n3"
          >mdi-close</v-icon
        >
      </v-toolbar>

      <v-card class="overflow-hidden" width="700px">
        <v-form ref="form">
          <v-row no-gutters class="mt-n2">
            <v-col cols="12" md="12" sm="12" lg="12" xl="12">
              <v-card class="ml-1 mt-n2" flat>
                <v-row no-gutters>
                  <v-col cols="12" md="6" sm="12" lg="6" xl="6">
                    <v-card-text align="center">
                      <v-text-field
                        v-model="location_name"
                        label="Enter Location Name *"
                        dense
                        :rules="loc_name_rules"
                        outlined
                        class="mr-2 mt-5"
                      ></v-text-field>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-form>

        <v-row v-if="locationObjectMain.actionObject == 'ADD'" no-gutters>
          <v-card-text align="center">
            <v-btn
              @click="validate_add_location()"
              :loading="locationLoading"
              class="gradient-bg white--text"
              ><v-icon class="mr-2">mdi-plus</v-icon>Add Location
            </v-btn>
          </v-card-text>
        </v-row>
        <v-row v-if="locationObjectMain.actionObject == 'EDIT'" no-gutters>
          <v-card-text align="center">
            <v-btn
              @click="validate_edit_location()"
              :loading="locationLoading"
              class="gradient-bg white--text"
              small
              ><v-icon class="mr-2">mdi-pencil</v-icon>Save
            </v-btn>
          </v-card-text>
        </v-row>
      </v-card>
    </v-dialog>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>
<script>
import SnackBar from "@/components/SnackBar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { EditLocations, create_visitor_location } from "@/graphql/mutations.js";
export default {
  components: {
    SnackBar,
  },
  props: {
    addLocationVisitorDialog: Boolean,
    locationObjectMain: Object,
  },
  data: () => ({
    switch1: true,
    location_name: "",
    search: "",
    defaultCenter: {
      lat: 0,
      lng: 0,
    },
    markers: [],
    locationLoading: false,
    cardLoading: false,
    latrules: [(v) => !!v || "Lattitude is required"],
    longrules: [(v) => !!v || "Longitude is required"],
    complete_address: "",
    latt: "",
    longg: "",
    radioGroup: "",
    SnackBarComponent: {},
    count: 0,
    loc_name_rules: [
      (v) => !!v || "Location Name is required",
      (v) => v.length != 0 || "Location Name cannot be empty",
      (v) => v.length <= 30 || "Location Name must be under 30 characters",
    ],
  }),
  watch: {
    addLocationVisitorDialog(val) {
      if (val == true) {
        if (this.locationObjectMain.actionObject == "ADD") {
          this.complete_address = "Current Location";
          this.cardLoading = true;
        }
        if (this.locationObjectMain.actionObject == "EDIT") {
          this.location_name = this.locationObjectMain.location_name;
        }
      }
    },
  },
  methods: {
    validate_edit_location() {
      if (this.$refs.form.validate()) {
        this.locationLoading = true;
        this.edit_location();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Enter Valid Inputs",
        };
      }
    },
    async edit_location() {
      this.locationLoading = true;
      try {
        var inputParam = {
          organisation_id:
            this.$store.getters.get_org_details[0].organisation_id,
          location_name: this.location_name,
          location_id: this.locationObjectMain.location_id,
          user_email_id: this.$store.getters.get_useremail,
          geo_lat: this.latt,
          geo_long: this.longg,
        };
        for (const [key, value] of Object.entries(inputParam)) {
          if (value == "") delete inputParam[key];
        }
        await API.graphql(
          graphqlOperation(EditLocations, {
            input: inputParam,
          })
        ).then((res) => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: res.data.EditLocations.Message,
          };
          this.$emit("close_edit_visitor_get_loc");
          this.locationLoading = false;
        });
      } catch (err) {
        console.log(err);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
        this.locationLoading = false;
      }
    },
    validate_add_location() {
      if (this.$refs.form.validate()) {
        this.locationLoading = true;
        this.add_location();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Enter Valid Inputs",
        };
      }
    },
    async add_location() {
      this.locationLoading = true;

      try {
        var inputParam = {
          organisation_id:
            this.$store.getters.get_org_details[0].organisation_id,
          location_name: this.location_name,
          user_email_id: this.$store.getters.get_useremail,
          location_geo_lat: "",
          location_geo_long: "",
        };
        for (const [key, value] of Object.entries(inputParam)) {
          if (value == "") delete inputParam[key];
        }
        await API.graphql(
          graphqlOperation(create_visitor_location, {
            input: inputParam,
          })
        ).then((res) => {
          this.locationLoading = false;
          this.close_dialog();
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: res.data.create_visitor_location.Message,
          };
        });
      } catch (err) {
        console.log(err);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
        this.locationLoading = false;
      }
    },

    close_dialog_check_for_source(location_id, location_name) {
      this.$refs.form.reset();
      this.location_name = "";

      this.$emit("close_location_dialog_check_src", location_id, location_name);
    },
    close_dialog() {
      this.location_name = "";

      this.$emit("close_visitor_location_dialog");
    },
  },
};
</script>

<style></style>
