<template>
  <div>
    <v-dialog v-model="viewGeoSwipesDialog" persistent width="800px">
      <v-toolbar dense class="elevation-0 gradient-bg white--text">
        <v-toolbar-title
          >Geo Location -
          {{
            userObject.user_name != undefined
              ? userObject.user_name
              : userObject.location_name
          }}</v-toolbar-title
        >
        <v-spacer />
        <v-icon @click="close_dialog()" class="white--text mr-n3"
          >mdi-close</v-icon
        >
      </v-toolbar>
      <v-card height="250px" class="overflow--hidden">
        <v-row class="mx-4" no-gutters>
          <v-col cols="12" md="5" sm="12" lg="5" xl="5">
            <v-card flat class="mr-2">
              <v-row class="mt-3" no-gutters>
                <v-col cols="12" md="12" lg="12" xl="12" sm="12">
                  <v-text-field
                    :label="
                      userObject.user_name != undefined
                        ? 'User Lattitude'
                        : 'Lattitude'
                    "
                    v-model="user_lat"
                    dense
                    outlined
                    readonly
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" lg="12" xl="12" sm="12">
                  <v-text-field
                    class="mt-n3"
                    :label="
                      userObject.user_name != undefined
                        ? 'User Longitude'
                        : 'Longitude'
                    "
                    v-model="user_lng"
                    dense
                    outlined
                    readonly
                    disabled
                  ></v-text-field>
                  <v-text-field
                    v-if="userObject.user_name != undefined"
                    class="mt-n3"
                    label="Swipe Location"
                    v-model="swipe_location"
                    dense
                    outlined
                    readonly
                    disabled
                  ></v-text-field>
                  <v-text-field
                    v-if="userObject.user_name != undefined"
                    class="mt-n3"
                    label="Swipped To QR"
                    v-model="swipe_qr"
                    dense
                    outlined
                    readonly
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" md="7" sm="12" lg="7" xl="7">
            <v-card class="mt-3">
              <GmapMap
                :center="
                  swipeData[0] != undefined
                    ? swipeData[0].position
                    : defaultCenter
                "
                :zoom="18"
                map-type-id="terrain"
                style="width: 100%; height: 200px"
              >
                <GmapMarker
                  :key="index"
                  v-for="(m, index) in swipeData"
                  :position="m.position"
                  :clickable="false"
                  :draggable="false"
                />
              </GmapMap>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <Overlay :overlay="overlay" />
  </div>
</template>

<script>
import Overlay from "@/components/Overlay.vue";

export default {
  components: {
    Overlay,
  },
  props: {
    viewGeoSwipesDialog: Boolean,
    userObject: Object,
    swipeData: Array,
  },
  data: () => ({
    overlay: false,
    user_lat: "",
    swipe_qr: "",
    swipe_location: "",
    user_lng: "",
    defaultCenter: {
      lng: 0,
      lat: 0,
    },
    // swipeData: [],
    geoSwipData: [],
  }),
  watch: {
    viewGeoSwipesDialog: {
      handler() {
        {
          this.overlay = true;
        // console.log(" this.userObject", this.userObject);
        this.swipe_location =
          this.userObject.box_location != undefined
            ? this.userObject.box_location
            : "";
        this.swipe_qr =
          this.userObject.qr_location_name != undefined
            ? this.userObject.qr_location_name
            : "";
        // console.log(this.userObject.qr_location_name);
        this.user_lat =
          this.userObject.user_lat != undefined
            ? this.userObject.user_lat
            : this.userObject.geo_lat;
        this.user_lng =
          this.userObject.user_long != undefined
            ? this.userObject.user_long
            : this.userObject.geo_long;
        this.overlay = false;
        }
      },
      immediate: true,
    },
  },
  // watch: {
  //   viewGeoSwipesDialog(val) {
  //     if (val == true) {
  //       this.overlay = true;
  //       console.log(" this.userObject", this.userObject);
  //       this.swipe_location =
  //         this.userObject.box_location != undefined
  //           ? this.userObject.box_location
  //           : "";
  //       this.swipe_qr =
  //         this.userObject.qr_location_name != undefined
  //           ? this.userObject.qr_location_name
  //           : "";
  //       console.log(this.userObject.qr_location_name);
  //       this.user_lat =
  //         this.userObject.user_lat != undefined
  //           ? this.userObject.user_lat
  //           : this.userObject.geo_lat;
  //       this.user_lng =
  //         this.userObject.user_long != undefined
  //           ? this.userObject.user_long
  //           : this.userObject.geo_long;
  //       this.overlay = false;
  //     }
  //   },
  // },
  methods: {
    close_dialog() {
      this.userObject = {};
      this.swipeData = [];
      this.$emit("close_view_geo_swipes_dialog");
    },
  },
};
</script>

<style></style>
