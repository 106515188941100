var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","width":"700px"},model:{value:(_vm.viewSourceDialog),callback:function ($$v) {_vm.viewSourceDialog=$$v},expression:"viewSourceDialog"}},[_c('v-toolbar',{staticClass:"gradient-bg elevation-0",attrs:{"dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("View Sources for Locations- "+_vm._s(_vm.locationObject.location_name))]),_c('v-spacer'),_c('v-icon',{staticClass:"white--text mr-n3",on:{"click":function($event){return _vm.close_dialog()}}},[_vm._v("mdi-close")])],1),_c('v-card',{staticClass:"overflow-hidden"},[_c('v-row',{staticClass:"mx-3 mt-3",attrs:{"no-gutters":""}},[_c('v-spacer')],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12","lg":"12","xl":"12"}},[_c('v-data-table',{staticClass:"overflow",attrs:{"headers":_vm.locationObject.location_name == 'SCAN_ANY_WHERE'
                ? _vm.headersInfo
                : _vm.viewSourceData,"items":_vm.viewSourceItems,"loading":_vm.isLoading,"loading-text":"Loading... Please wait","dense":"","items-per-page":5},scopedSlots:_vm._u([{key:"item.sourceType",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-5",attrs:{"small":""},domProps:{"textContent":_vm._s(
                      item.location_source_type == 'BOX'
                        ? 'mdi-account-credit-card'
                        : item.location_source_type == 'BEACON'
                        ? 'mdi-alpha-b-box-outline'
                        : item.location_source_type == 'RFID'
                        ? 'mdi-card-account-details-outline'
                        : 'mdi-qrcode-scan'
                    )}},on))]}}],null,true)},[_c('span',{staticClass:"white--text",domProps:{"textContent":_vm._s(
                    item.location_source_type == 'BOX'
                      ? 'Loaction'
                      : item.location_source_type == 'BEACON'
                      ? 'Beacon'
                      : item.location_source_type == 'RFID'
                      ? 'RFID'
                      : 'QR'
                  )}})])]}},{key:"item.qr_location_name",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('div',_vm._g({staticClass:"overflow"},on),[_vm._v(" "+_vm._s(item.qr_location_name)+" ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.qr_location_name))])])]}},{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text"},[_vm._v("ADD SOURCE FOR LOCATION ")]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"primary white--text mb-2",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.add_src_for_location()}}},on),[_c('span',[_vm._v("add source")]),_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',{staticClass:"white--text"},[_vm._v("Add Source")])])]},proxy:true},{key:"item.view_qr",fn:function(ref){
                  var item = ref.item;
return [(!['BEACON', 'BOX'].includes(item.location_source_type))?_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('a',{on:{"click":function($event){return _vm.view_qr(item)}}},[_c('v-icon',_vm._g({staticClass:"primary--text mr-1",attrs:{"small":""}},on),[_vm._v("mdi-qrcode ")])],1)]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("View QR")])]):_c('div',[_vm._v("N/A")])]}},{key:"item.url",fn:function(ref){
                  var item = ref.item;
return [(!['BEACON', 'BOX'].includes(item.location_source_type))?_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(
                      ("https://www.prznce.com/QR/#/" + (item.qr_id))
                    ),expression:"\n                      `https://www.prznce.com/QR/#/${item.qr_id}`\n                    ",arg:"copy"}],attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.doCopy()}}},'v-icon',attrs,false),on),[_vm._v("mdi-content-copy")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(("https://www.prznce.com/QR/#/" + (item.qr_id))))])]):_c('div',[_vm._v("N/A")])]}},{key:"item.Actions",fn:function(ref){return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.add_src_for_location()}}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Add Source")])])]}}],null,true)})],1)],1)],1)],1),_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('AddSourceForLocation',{attrs:{"action":_vm.action,"send_LocationId":_vm.send_LocationId,"send_LocationName":_vm.send_LocationName,"addSourceLocationDialog":_vm.addSourceLocationDialog,"SourceObject":_vm.SourceObject},on:{"close_src_location_dialog":function($event){_vm.addSourceLocationDialog = false},"close_src_loc_get_src":_vm.get_src,"close_src_loc_get_location":_vm.get_location}}),_c('ViewLocationMemberQR',{attrs:{"viewMemberQRDialog":_vm.viewMemberQRDialog,"userDetailsObj":_vm.userDetailsObj},on:{"close_view_qr_dialog":function($event){_vm.viewMemberQRDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }